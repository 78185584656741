import Vue from 'vue'
import { validationMixin } from 'vuelidate';

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			readonly: false,
			ignoreUnsaved: false,
			references: {},
			settings: false,
			is: {
				failed: false,
				loading: true,
				error: false,
				saving: false,
				initialised: false
			},
			enableStash: false,
			item: [],
			callbacks: [],
			original: []
		}

	},

	created: function() {

		this.load()

	},

	computed: {

		stashName: function() {

			return ['form', this.$route.path].join('/')

		},

		title: function() {

			return (this.isNew) ? 'Create' : this.item.name
		
		},

		isLoading: function() {

			return this.is.loading

		},

		isValid: function() {

			return !this.$v.$invalid

		},

		isNew: function() {

			return (this.$route.params.id === 'new' && !this.model.id && !this.settings) || (this.standalone && !this.model.id)

		},

		isDirty: function() {

			return !this.$_.isEqual(this.model, this.original)

		}

	},

	beforeRouteEnter: function(to, from, next) {

		if (from.meta.stash === to.name) {

			next(vm => {

				vm.enableStash = true

				Vue.set(vm, 'original', vm.$util.copy(vm.model))
				
				if (vm.$store.getters['stash'][vm.stashName] && vm.enableStash) {

					Vue.set(vm, 'model', vm.$util.copy(vm.$store.getters['stash'][vm.stashName]))
		
				}

				vm.runCallbacks()

			})

		} else {

			next(vm => {

				vm.runCallbacks()

			})

		}

	},

	beforeRouteLeave: function(to, from, next) {

		if (to.meta.stash === from.name || this.isDirty) {

			this.$store.commit('stash', {
				name: this.stashName,
				data: this.$util.copy(this.model)
			})

		} 

		if (this.isDirty && !this.standalone && !to.meta.stash && !this.ignoreUnsaved && !this.readonly) {

			next({
				name: from.name.replace('.Edit', '.Unsaved'),
				params: {
					id: this.model.id || this.$route.params.id
				},
				query: {
					redirect: to.path
				}
			})

		} else [

			next()

		]

	},

	watch: {

		model: {

			deep: true,
			handler: function() {

				this.$v.$touch()

			}

		}

	},

	methods: {

		onRefresh: function() {

			this.load()

		},

		runCallbacks: function() {

			this.$_.each(this.callbacks, function(callback) {

				if (this.$route.params[callback]) this[callback](this.$route.params[callback])

			}.bind(this))

		},

		clearStash: function() {

			this.$store.commit('stash', {
				name: this.stashName,
				data: false
			})

		},

		load: function() {

			var params = {}

			if (this.$route.query.clone) {

				params.clone = this.$route.query.clone

			}

			if (this.onCreateNew) {

				params = this.onCreateNew(params)

			}

			if (this.isNew || this.settings || this.standalone) {

				if (this.settings) {

					var model = {}
					
					var settings = this.$util.copy(this.defaultSettings || this.$convention.settings)

					this.$_.each(this.model, function(value, key) {

						model[key] = settings[key]
					
					})
					
					if (this.settings) Vue.set(this, 'model', model)
				
				} 

				if (!this.enableStash) Vue.set(this, 'original', this.$util.copy(this.model))

				if (this.$_.keys(this.references).length || this.forceModelLoad) {
					
					this.$api.get((this.settings || this.standalone) ? this.$endpoint(this.$route.meta.api) : [this.$endpoint(this.$route.meta.api), this.model.id || this.$route.params.id], params).then(function(json) {

						this.$_.each(this.references, function(value, key) {
	
							Vue.set(this.references, key, json[key])
	
						}.bind(this))

						if (json.model) Vue.set(this, 'model', json.model)
						if (json.item) Vue.set(this, 'item', json.item)

						if (json.model) Vue.set(this, 'original', this.$util.copy(this.model))
						
						if (this.onModelUpdate) this.onModelUpdate()

						this.$nextTick(function() {
	
							this.is.loading = false
							this.is.initialised = true

						}.bind(this))
	
					}.bind(this), function() {

						if (this.onLoadFailed) this.onLoadFailed()

						this.is.failed = true
						this.is.loading = false
						this.is.initialised = true

					}.bind(this))

				} else {

					this.is.loading = false
					this.is.initialised = true

				}

			} else {

				this.is.loading = true

				this.loadItem(params)
				
			}

		},

		loadItem: function(params) {

			params = params || {}

			return this.$api.get([this.$endpoint(this.$route.meta.api), this.model.id || this.$route.params.id], params).then(function(json) {

				Vue.set(this, 'item', this.$util.copy(json.item))
				if (!this.enableStash) Vue.set(this, 'model', this.$util.copy(json.model))
				Vue.set(this, 'original', this.$util.copy(json.model))

				this.$_.each(this.references, function(value, key) {

					Vue.set(this.references, key, json[key])

				}.bind(this))

				if (this.onModelUpdate) this.onModelUpdate()

				this.is.loading = false
				this.is.initialised = true

			}.bind(this), function() {

				if (this.onLoadFailed) this.onLoadFailed()

				this.is.failed = true
				this.is.loading = false
				this.is.initialised = true

			}.bind(this))

		},

		onSaveClick: function() {

			if (this.readonly) return false

			this.$v.$touch()

			if (this.$v.$invalid) {

				this.is.error = true

			} else {
				
				this.is.error = false

				this.clearStash()

				this.is.saving = true
	
				this.$api[(this.isNew) ? 'post' : 'put']((this.settings || this.standalone) ? this.$endpoint(this.$route.meta.api) : [this.$endpoint(this.$route.meta.api), this.model.id || this.$route.params.id], this.model).then(function(json) {

					if (this.settings) {

						this.$notify({
							message: ['You have successfully saved the settings.']
						})

					} else {

						this.$notify({
							message: ['You have successfully', this.isNew ? 'created' : 'saved', 'the', this.$route.meta.noun, '<b>', this.model.name, '</b>.']
						})

						if (json.item) Vue.set(this, 'item', this.$util.copy(json.item))
						if (json.model) Vue.set(this, 'model', this.$util.copy(json.model))

						if (this.onModelUpdate) this.onModelUpdate()

					}

					Vue.set(this, 'original', this.$util.copy(this.model))

					if (this.onSaved) this.onSaved()

					this.is.saving = false

				}.bind(this), function() {

					this.$notify({
						message: ['Sorry, this', this.$route.meta.noun, 'could not be saved. Please try again.'],
						type: 'error'
					})

					this.is.saving = false

				}.bind(this))

			}

		},

		onUndoClick: function() {

			if (this.readonly) return false

			Vue.set(this, 'model', this.$util.copy(this.original))

		},

		onDeleteClick: function() {

			if (this.readonly) return false

			this.$router.push({
				name: this.$route.name.replace(/.Edit$/, '.Delete'),
				params: {
					id: this.model.id || this.$route.params.id
				}
			})

		},

		saveURL: function(value) {

			if (this.$_.isArray(value)) value = value.join('/')

			var url = [this.$endpoint(this.$route.meta.api), this.model.id || this.$route.params.id]
			
			if (value) url.push(value)

			return url

		}

	}

}